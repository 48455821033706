import React, { useState } from 'react';

const HeaderNav = () => {
    const [visible, setVisible] = useState(false);
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;

        if (scrolled > 1) {
            setVisible(true)
        }
        else if (scrolled <= 0) {
            setVisible(false)
        }
    };
    window.addEventListener('scroll', toggleVisible);
    return (
        <>
            <nav className={`navbar navbar-b navbar-expand-md fixed-top ${visible ? "navbar-reduce" : "navbar-trans"}`} id="mainNav">
            <div className="container">
                <a className="navbar-brand js-scroll" href="#page-top">Sushil Gurung</a>
                <button className="navbar-toggler collapsed" type="button" >
                    @*data-toggle="collapse"
                    data-target="#navbarDefault"
                    aria-controls="navbarDefault"*@
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <div className="navbar-collapse collapse justify-content-end" id="navbarDefault">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link js-scroll active" href="#home">Home</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link js-scroll" href="#about">About</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link js-scroll" href="#service">Services</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link js-scroll" href="#work">Work</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link js-scroll" href="#blog">Certificate</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link js-scroll" href="#contact">Contact</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link js-scroll" href="http://blog.sushilgurung.com.np/">Blog</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        </>
        
    )
}

export default HeaderNav
