import React, { Component } from 'react';
import Typewriter from 'typewriter-effect';
import ScrollTopButton from '../components/ScrollTop/scrollTopButton';
import HeaderNav from '../components/HeaderNav/HeaderNav';

export class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
      show: false
    }

  }

  componentDidMount() {

  }



  render() {

    return (
      <>
        {/* <!--/ Nav Star /--> */}
        <HeaderNav />
        {/* <!--/ Nav End /--> */}


        {/* <!--/ Intro Skew Star /--> */}
        <div id="home" className="intro route bg-image"
          style={{ backgroundImage: 'url("img/sushilgurung.png")', backgroundColor: "black" }}  >
          <div className="overlay-itro"></div>
          <div className="intro-content display-table">
            <div className="table-cell">
              <div className="container">
                <h1 className="intro-title mb-4">
                  <Typewriter
                    options={{
                      strings: ['I am Sushil Gurung'],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </h1>

                <p className="intro-subtitle">

                  <Typewriter
                    options={{
                      strings: ['Software Developer', 'Software Engineer', 'Web Developer', 'Web Designer', 'Backend Developer'],
                      autoStart: true,
                      loop: true,
                    }}
                  />

                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <!--/ Intro Skew End /--> */}




        {/* <!-- About Start --> */}
        <section id="about" className="about-mf sect-pt4 route">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-lg-4 ">
                <div className="div-img-bg">
                  <div className="about-img">
                    <img src="./img/sushilgurung.jpg" className="img-responsive" alt="Sushil Gurung" />
                  </div>
                </div>
              </div>

              <div className="col-lg-7">
                <div className="about-descr text-center">

                  <p className="p-heading">
                    Hi, I'm Sushil a full stack Software Developer with a demonstrated history of working in the computer
                    software industry. Skilled in ASP.NET, C#, Aws, JavaScript, Angular, React-native, and Java. Strong
                    Information technology professional.
                  </p>
                  <p className="separator">
                    I’m currently available for freelance work. If you have a project that you want to get
                    started or think you need my help with something, then get in touch.
                  </p>
                  <a href="https://docs.google.com/document/d/e/2PACX-1vS-Qm6gs2gpfN4Dz-zByYS-wH-HynBHB4kuGh32wMo7so2Aq9znTwDdzkzaFKw0JUCWLEaSEMjC-O4f/pub"
                    className="btn btn-fill" target="_blank" download="">Download my cv</a>
                </div>

              </div>
            </div>
          </div>
        </section>
        {/* <!--/ About End /--> */}



        {/* <!--/ Section Services Star /--> */}
        <section id="service" className="services-mf route">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="title-box text-center">
                  <h3 className="title-a">
                    WHAT I DO
                  </h3>
                  <p className="subtitle-a">

                  </p>
                  <div className="line-mf"></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="service-box">
                  <div className="service-ico">
                    <span className="ico-circle"><i className="ion-monitor"></i></span>
                  </div>
                  <div className="service-content">
                    <h2 className="s-title">Software Development</h2>
                    <p className="s-description text-center">
                      Software and application development: custom Software Development: Standalone, Web based and Distributed
                      Systems.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="service-box">
                  <div className="service-ico">
                    <span className="ico-circle"><i className="ion-code-working"></i></span>
                  </div>
                  <div className="service-content">
                    <h2 className="s-title">Web Development</h2>
                    <p className="s-description text-center">
                      Develop a wide range of web applications,
                      simple to complex, from a simple contact form to a word processor to multi-player gaming app.
                      but also, minimizes operation cost, significantly.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="service-box">
                  <div className="service-ico">
                    <span className="ico-circle"><i className="ion-android-phone-portrait"></i></span>
                  </div>
                  <div className="service-content">
                    <h2 className="s-title">Responsive Design</h2>
                    <p className="s-description text-center">
                      Website that meets your business need.
                      Build everything out of scratch and provide easy to handle features to make your operation easier
                      tested, responsive, marketable and designed for engagement and conversion.
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
        {/* <!--/ Section Services End /--> */}


        {/* <!--/ Section Testimonials Star /--> */}
        <div id="work" className="testimonials paralax-mf bg-image" style={{ backgroundImage: 'url("img/overlay-bg.jpg")', backgroundColor: "black" }}>
          <div className="col-sm-12">
            <div className="title-box text-center">
              <h3 className="title-a">
                Portfolio
              </h3>
              <p className="subtitle-a">
                My Portfolio
              </p>
              <div className="line-mf"></div>
            </div>
          </div>
          <div className="overlay-mf"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div id="testimonial-mf" className="owl-carousel owl-theme">
                  <div className="testimonial-box">
                    <div className="author-test">
                      <a href="https://www.braindigit.com/"><img src="./img/bd-optimized.png" alt="" className="b-shadow-a" /></a>
                      <span className="author">Braindigit It Solution</span>
                    </div>
                    <div className="content-test">
                      <p className="description lead">
                        I worked at Braindigit more then 3 years.
                      </p>
                      <span className="comit"><i className="fa fa-quote-right"></i></span>
                    </div>
                  </div>
                  <div className="testimonial-box">
                    <div className="author-test">
                      <a href="https://xtrastaff.com/"> <img src="./img/xtrastaff-logo.png" alt="" className="b-shadow-a" /></a>
                      <span className="author">XtraStaff</span>
                    </div>
                    <div className="content-test">
                      <p className="description lead">
                        I worked at XtraStaff more then 2 years.
                      </p>
                      <span className="comit"><i className="fa fa-quote-right"></i></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* <!--/ Section Blog Star /--> */}
        <section id="blog" className="blog-mf sect-pt4 route">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="title-box text-center">
                  <h3 className="title-a">
                    CERTIFIED
                  </h3>
                  <p className="subtitle-a">

                  </p>
                  <div className="line-mf"></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="card card-blog">
                  <div className="card-img">
                    <a href="https://www.coursera.org/account/accomplishments/certificate/EJB6RHQE5PXN">
                      <img src="./img/html5.png" alt="" className="img-fluid"/>
                    </a>
                  </div>
                  <div className="card-body">
                    <div className="card-category-box">
                      <div className="card-category">
                        <h6 className="category">Html 5</h6>
                      </div>
                    </div>
                    <h3 className="card-title">
                      <a href="https://www.coursera.org/account/accomplishments/certificate/EJB6RHQE5PXN">Html 5</a>
                    </h3>
                    <p className="card-description">

                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card card-blog">
                  <div className="card-img">
                    <a href="https://www.coursera.org/account/accomplishments/certificate/DNWE5X9MTA2E">
                      <img src="./img/javascript.png" alt="" className="img-fluid"/>
                    </a>
                  </div>
                  <div className="card-body">
                    <div className="card-category-box">
                      <div className="card-category">
                        <h6 className="category">Javascript</h6>
                      </div>
                    </div>
                    <h3 className="card-title">
                      <a href="https://www.coursera.org/account/accomplishments/certificate/DNWE5X9MTA2E">Javascript</a>
                    </h3>
                    <p className="card-description">

                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card card-blog">
                  <div className="card-img">
                    <a href="https://drive.google.com/file/d/1J9aL3gzsmPcAABS7pMGcIvcCH6_tSzqi/view">
                      <img src="./img/Microsoft-virtual-academy.png" alt="" className="img-fluid"/>
                    </a>
                  </div>
                  <div className="card-body">
                    <div className="card-category-box">
                      <div className="card-category">
                        <h6 className="category">C# Fundamentals</h6>
                      </div>
                    </div>
                    <h3 className="card-title">
                      <a href="https://drive.google.com/file/d/1J9aL3gzsmPcAABS7pMGcIvcCH6_tSzqi/view">C# Fundamentals for Absolute Beginners</a>
                    </h3>
                    <p className="card-description">

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--/ Section Blog End /--> */}
        {/* <!--/ Section Contact-Footer Star /--> */}
        <section className="paralax-mf footer-paralax bg-image sect-mt4 route" style={{ backgroundImage: 'url("img/overlay-bg.jpg")' }}>
          <div className="overlay-mf"></div>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="contact-mf">
                  <div id="contact" className="box-shadow-full">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="title-box-2">
                          <h5 className="title-left">
                            Send Message Us
                          </h5>
                        </div>
                        <div>
                          <form action="" method="post" role="form" className="contactForm">
                            <div id="sendmessage">Your message has been sent. Thank you!</div>
                            <div id="errormessage"></div>
                            <div className="row">
                              <div className="col-md-12 mb-3">
                                <div className="form-group">
                                  <input type="text" name="name" className="form-control" id="name" required placeholder="Your Name"
                                    data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                                  <div className="validation"></div>
                                </div>
                              </div>
                              <div className="col-md-12 mb-3">
                                <div className="form-group">
                                  <input type="email" className="form-control" name="email" id="email" required placeholder="Your Email"
                                    data-rule="email" data-msg="Please enter a valid email" />
                                  <div className="validation"></div>
                                </div>
                              </div>
                              <div className="col-md-12 mb-3">
                                <div className="form-group">
                                  <input type="text" className="form-control" name="subject" id="subject" required placeholder="Subject"
                                    data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                                  <div className="validation"></div>
                                </div>
                              </div>
                              <div className="col-md-12 mb-3">
                                <div className="form-group">
                                  <textarea className="form-control" name="message" required rows="5" data-rule="required"
                                    data-msg="Please write something for us" placeholder="Message"></textarea>
                                  <div className="validation"></div>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <button type="submit" className="btn btn-border">
                                  Send Message
                                  <span className="glyphicon glyphicon-send"></span>
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="title-box-2 pt-4 pt-md-0">
                          <h5 className="title-left">
                            Get in Touch
                          </h5>
                        </div>
                        <div className="more-info">
                          <p className="lead">

                          </p>
                          <ul className="list-ico">
                            {/* <!-- <li><span className="ion-ios-location"></span> Kathmandu, Nepal</li>
                            <li><span className="ion-ios-telephone"></span> (617) 557-0089</li> --> */}
                            <li><span className="ion-email"></span> sushil.grunge@gmail.com</li>
                          </ul>
                        </div>
                        <div className="socials">
                          <ul>
                            <li><a href="https://www.linkedin.com/in/sushil-gurung-a5a06481/"><span className="ico-circle"><i className="ion-social-linkedin"></i></span></a></li>
                            <li><a href="https://github.com/sushilgurung"><span className="ico-circle"><i className="ion-social-github"></i></span></a></li>
                            <li><a href="https://www.facebook.com/shoexill.gurung/"><span className="ico-circle"><i className="ion-social-facebook"></i></span></a></li>
                            <li><a href=""><span className="ico-circle"><i className="ion-social-instagram"></i></span></a></li>
                            <li><a href="https://twitter.com/_sushilgurung"><span className="ico-circle"><i className="ion-social-twitter"></i></span></a></li>

                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer>
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="copyright-box">
                    <p className="copyright">Copyright &copy; <strong>Sushilgurung.com.np 2016 - @DateTime.Now.Year</strong>. All Rights Reserved</p>
                    <div className="credits">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </section>
        {/* <!--/ Section Contact-footer End /--> */}

        <ScrollTopButton />
      </>
    )
  }
}

export default Home